import {FC} from 'react';
import {
  AuthorBox,
  AuthorName,
  AuthorPosition,
  Avatar,
  CardBox,
  CommentText,
} from './Card.styled';

interface ICard {
  person: {
    readonly name: string;
    readonly position: string;
    readonly avatar: string;
  };
  readonly comment: string;
}

const Card: FC<ICard> = ({person, comment}) => {
  return (
    <CardBox>
      <CommentText>{comment}</CommentText>
      <AuthorBox>
        <Avatar src={person.avatar} alt={`${person.name}'s avatar`} />
        <div>
          <AuthorName>{person.name}</AuthorName>
          <AuthorPosition>{person.position}</AuthorPosition>
        </div>
      </AuthorBox>
    </CardBox>
  );
};

export {Card};
