import {ISignIn} from 'pages/signIn/SignIn';
import api from 'api/axios';
import {useAppDispatch, useAppSelector} from './redux';
import {
  AuthState,
  logIn as logInRedux,
  logOut as logOutRedux,
  refreshToken as refreshTokenRedux,
} from 'store/reducers/auth/authSlice';
import {ISignUp} from 'pages/signUp/SignUp';

const useAuthApi = () => {
  const dispatch = useAppDispatch();
  const {token, isLoggedIn} = useAppSelector(state => state.auth) as AuthState;

  const logIn = async (data: ISignIn) => {
    try {
      const res = await api.post('/auth/login', data);
      const {token, user} = res.data;
      dispatch(logInRedux({user, token}));
      api.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`;
      return user;
    } catch (error) {
      console.log('Error in logIn, useAuth.ts: ', error);
      return null;
    }
  };

  const signUp = async (data: ISignUp) => {
    try {
      const res = await api.post('/auth/register', data);
      return res?.data?.user;
    } catch (error) {
      console.log('Error in signUp, useAuth.ts: ', error);
      return null;
    }
  };

  const confirmEmail = async (confirmEmailToken: string) => {
    try {
      const res = await api.get('/auth/verify-email', {
        params: {token: confirmEmailToken},
      });
      const {token, user} = res.data;
      dispatch(logInRedux({user, token}));
      api.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`;
      return user;
    } catch (error) {
      console.log('Error in confirmEmail, useAuth.ts: ', error);
      return null;
    }
  };

  const setupRefreshInterceptor = async () => {
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token.access_token}`;
    }
    api.interceptors.response.use(
      response => response,
      async error => {
        const {refresh_token: old_refresh_token} = token ?? {};
        const originalRequest = error.config;
        if (
          error.response.status === 401 &&
          isLoggedIn &&
          old_refresh_token &&
          !originalRequest._retry
        ) {
          try {
            originalRequest._retry = true;
            const response = await api.post('/auth/refresh-token', {
              refresh_token: old_refresh_token,
            });
            const {token} = response.data;

            dispatch(refreshTokenRedux(token));

            api.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${token?.access_token}`;
            originalRequest.headers['Authorization'] = `Bearer ${token?.access_token}`;

            return api(originalRequest);
          } catch (refreshError) {
            dispatch(logOutRedux());
            api.defaults.headers.common['Authorization'] = null;
          }
        }
      },
    );
  };

  const logOut = () => {
    dispatch(logOutRedux());
    api.defaults.headers.common['Authorization'] = null;
  };

  return {logIn, signUp, setupRefreshInterceptor, logOut, confirmEmail};
};

export {useAuthApi};
