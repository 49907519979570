import styled from 'styled-components';
import {getColor, getFontSize, getSpacing} from 'theme';
import quote from 'assets/quote_icon.svg';

const CardBox = styled.li`
  height: 100%;
  width: 282px;
  padding: ${getSpacing('lg')};
  padding-top: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  background-image: url(${quote});
  background-size: 68px 68px;
  background-position: 12px 9px;
  background-repeat: no-repeat;
`;

const CommentText = styled.p`
  font-size: 14px;
  margin-bottom: 72px;
`;

const AuthorBox = styled.div`
  position: absolute;
  bottom: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
`;

const AuthorName = styled.p`
  font-size: ${getFontSize('pSm')};
  font-weight: 700;
  margin-bottom: 2px;
`;

const AuthorPosition = styled.p`
  font-size: 14px;
  color: ${getColor('secText')};
`;

export {CardBox, CommentText, AuthorBox, Avatar, AuthorName, AuthorPosition};
