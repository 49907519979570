import {FC} from 'react';
import {DefaultSectionContainer} from 'shared-ui-elements/DefaultSectionContainer.styled';
import {CardsContainer, NoBrakeText, ReviewsHeading} from './Reviews.styled';
import avatar from 'assets/comment_avatar.jpg';
import {Card} from './Card';
import {Slider} from 'modules/Slider/Slider';

const reviewsData = [
  {
    person: {name: 'JOhn D.', position: 'Designer', avatar: avatar},
    comment: 'So easy to use! Found a great psychiatrist in no time.',
  },
  {
    person: {name: 'JOhn D.', position: 'Designer', avatar: avatar},
    comment: 'So easy to use! Found a great psychiatrist in no time.',
  },
  {
    person: {name: 'JOhn D.', position: 'Designer', avatar: avatar},
    comment: 'So easy to use! Found a great psychiatrist in no time.',
  },
  {
    person: {name: 'JOhn D.', position: 'Designer', avatar: avatar},
    comment: 'So easy to use! Found a great psychiatrist in no time.',
  },
];

const Reviews: FC = () => {
  return (
    <DefaultSectionContainer>
      <ReviewsHeading>
        What our patients <NoBrakeText>say ...</NoBrakeText>
      </ReviewsHeading>
      <div>
        <CardsContainer>
          <Slider data={reviewsData} slide={Card} />
        </CardsContainer>
      </div>
    </DefaultSectionContainer>
  );
};

export {Reviews};
