import styled from 'styled-components';
import {getColor} from 'theme';
import arrowIcon from 'assets/arrow.svg';
import {NavLink} from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: ${({theme}) => (theme.isDesktop ? 'space-between' : 'center')};
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '16px 16px';
      case isTablet:
        return '32px 24px';

      default:
        return '56px 40px';
    }
  }};
`;

const SideBar = styled.div`
  width: 260px;
`;

const NavMenu = styled.nav`
  width: inherit;
  margin-bottom: 28px;
`;

const NavLinkStyled = styled(NavLink)`
  display: flex;
  padding: 16px;
  align-items: center;
  color: ${getColor('mainText')};
  /* &::before {
    content: '';
    display: block;
    width: 16px;
    height: 8px;
    margin-right: 12px;
  } */

  &.active::before {
    content: '';
    display: block;
    width: 16px;
    height: 8px;
    background-image: url(${arrowIcon});
    margin-right: 12px;
  }

  &:not(:last-child) {
    border-bottom: 0.5px solid rgba(204, 204, 204, 1);
  }
`;

export {Container, SideBar, NavMenu, NavLinkStyled};
