import styled from 'styled-components';

const BtnWrap = styled.button`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 10px;
  font-size: 16px;
`;

export {BtnWrap};
