import {ErrorMessage, Field} from 'formik';
import styled, {css} from 'styled-components';

interface InputProps {
  readonly $variant?: 'white_regular' | 'black_regular' | 'white_grey_placeholder';
  readonly $isError?: boolean;
  readonly $width?: string;
  readonly disabled?: boolean;
}

const errorColor = '#e03d78';
// const errorColor = 'rgba(180, 35, 24, 1)';

const styles = css<InputProps>`
  background-color: rgba(255, 255, 255, 0.1);
  border: ${props => {
    if (props.disabled) {
      return `1px solid ${props.theme.colors.secText}`;
    }
    if (props.$isError) {
      return `1px solid ${errorColor}`;
    }
    if (props.$variant === 'black_regular') {
      return `1px solid ${props.theme.colors.mainText}`;
    }
    return `1px solid ${props.theme.colors.lightText}`;
  }};
  border-radius: ${props => {
    if (props.$variant === 'black_regular') {
      return '8px';
    }
    return '50px';
  }};
  padding: ${props => {
    if (props.$variant === 'black_regular') {
      return '8px';
    }
    return '12px 24px';
  }};
  width: ${props => props.$width};
  color: ${props => {
    if (props.disabled) {
      return props.theme.colors.secText;
    }
    if (props.$isError) {
      return errorColor;
    }
    if (props.$variant === 'black_regular') {
      return props.theme.colors.mainText;
    }
    return props.theme.colors.lightText;
  }};

  &::placeholder {
    color: ${props => {
      if (props.$isError) {
        return errorColor;
      }
      if (props.$variant === 'black_regular') {
        return 'rgba(102, 102, 102, 1)';
      }
      if (props.$variant === 'white_grey_placeholder') {
        return 'rgba(204, 204, 204, 1)';
      }
      return props.theme.colors.lightText;
    }};
  }
`;

const DefaultInput = styled.input<InputProps>`
  ${styles}
`;

const DefaultFormikInput = styled(Field)<InputProps>`
  ${styles}
`;

const FormikErrorMsg = styled(ErrorMessage)<{
  $variant?: 'white_regular' | 'black_regular' | 'white_grey_placeholder';
}>`
  padding: ${props => {
    if (props.$variant === 'black_regular') {
      return '4px 0px';
    }
    return '4px 16px';
  }};
  display: block;
  font-size: 14px;
  color: ${errorColor};
`;

const DefaultLabel = styled.label<{
  $isError?: boolean;
  $variant?: 'white_regular' | 'black_regular' | 'white_grey_placeholder';
}>`
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding-left: ${props => {
    if (props.$variant === 'black_regular') {
      return '0px';
    }
    return '16px';
  }};
  margin-bottom: 8px;
  color: ${props => {
    if (props.$isError) {
      return errorColor;
    }
    if (props.$variant === 'black_regular') {
      return props.theme.colors.mainText;
    }
    return props.theme.colors.lightText;
  }};
`;

DefaultInput.defaultProps = {
  $variant: 'white_regular',
  $isError: false,
  $width: '364px',
};

export {DefaultInput, DefaultFormikInput, FormikErrorMsg, DefaultLabel};
