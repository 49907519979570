import styled from 'styled-components';
import {getFontSize} from 'theme';

interface ButtonProps {
  readonly $variant?: 'blue' | 'white' | 'transparent_black' | 'transparent_white';
  readonly $padding?: string;
}

const DefaultButton = styled.button<ButtonProps>`
  background-color: ${props => {
    if (props.$variant === 'white') {
      return props.theme.colors.mainBg;
    }
    if (props.$variant === 'blue') {
      return props.theme.colors.secBg;
    }
    return 'transparent';
  }};
  color: ${props => {
    if (props.$variant === 'white' || props.$variant === 'transparent_black') {
      return props.theme.colors.mainText;
    }
    return props.theme.colors.lightText;
  }};
  padding: ${props =>
    props.$padding ?? `${props.theme.spacing.sm} ${props.theme.spacing.md}`};
  border-radius: 50px;
  border: ${props => {
    if (props.$variant === 'transparent_black') {
      return `1px solid ${props.theme.colors.mainText}`;
    }
    if (props.$variant === 'transparent_white') {
      return `1px solid ${props.theme.colors.lightText}`;
    }
    if (props.$variant === 'blue') {
      return `1px solid ${props.theme.colors.secBg}`;
    }
    return 'none';
  }};
  font-size: ${getFontSize('pSm')};
  width: fit-content;
`;

DefaultButton.defaultProps = {
  $variant: 'blue',
};

export {DefaultButton};
