import {FC, useEffect, useState} from 'react';
import {Header} from './Header/Header';
import {Footer} from './Footer/Footer';
import {Container, Main, MainWrap, OutletWrap} from './SharedLayout.styled';
import {Outlet} from 'react-router-dom';
import {MobileMenu} from './MobileMenu/MobileMenu';
import {useTheme} from 'styled-components';

const SharedLayout: FC = () => {
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);
  const {isDesktop} = useTheme();

  useEffect(() => {
    if (isDesktop) {
      setIsShowMobileMenu(false);
    }
  }, [isDesktop]);

  const closeMenu = () => setIsShowMobileMenu(false);

  return (
    <Container>
      <Header
        onMobileMenuToggle={setIsShowMobileMenu}
        isShowMobileMenu={isShowMobileMenu}
      />
      <MainWrap>
        <Main>
          <MobileMenu isHidden={!isShowMobileMenu} onLinkClick={closeMenu} />
          <OutletWrap $isHidden={isShowMobileMenu}>
            <Outlet />
          </OutletWrap>
        </Main>
      </MainWrap>
      {!isShowMobileMenu && <Footer />}
    </Container>
  );
};

export {SharedLayout};
