import {createGlobalStyle} from 'styled-components';
import {getColor} from 'theme';

const GlobalStyle = createGlobalStyle`
   body {
     background-color: ${getColor('mainBg')};
   }
 `;

export {GlobalStyle};
