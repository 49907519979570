import {useAppSelector} from './hooks/redux';
import {FC} from 'react';
import {Navigate} from 'react-router-dom';
import {AuthState} from 'store/reducers/auth/authSlice';

interface IAdminRoute {
  readonly component: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  readonly redirectTo?: string;
}

const AdminRoute: FC<IAdminRoute> = ({component: Component, redirectTo = '/'}) => {
  const {isLoggedIn, isLoading, user} = useAppSelector(state => state.auth) as AuthState;

  const shouldRedirect =
    (!isLoggedIn && !isLoading) || (user && !isLoading && !user.role.includes('admin'));
  return shouldRedirect ? <Navigate to={redirectTo} replace /> : Component;
};

export default AdminRoute;
