import styled from 'styled-components';

const DefaultSectionContainer = styled.section<{$paddingV?: 'sm' | 'lg'}>`
  width: 100%;
  max-width: 1200px;
  padding-top: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '40px';
      case isTablet:
        return props.$paddingV === 'sm' ? '80px' : '60px';

      default:
        return props.$paddingV === 'sm' ? '112px' : '80px';
    }
  }};

  padding-bottom: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '40px';
      case isTablet:
        return props.$paddingV === 'sm' ? '80px' : '60px';

      default:
        return props.$paddingV === 'sm' ? '112px' : '80px';
    }
  }};
  ${props =>
    props.theme.isDesktop
      ? {}
      : {
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
`;

export {DefaultSectionContainer};
