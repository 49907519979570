import {FC} from 'react';
import {
  Header,
  HeroContainer,
  HeroFigure1,
  HeroFigure2,
  HeroFigure3,
  TextBox,
  Text,
  BoldText,
  ButtonsBox,
} from './Hero.styled';
import {DefaultButton} from 'shared-ui-elements/DefaultButton.styled';
import {useNavigate} from 'react-router-dom';
import {adultClients, childClients} from 'constants/clientFocus';

const Hero: FC = () => {
  const navigate = useNavigate();
  const onAdultPsychiatristsClick = () => {
    navigate(`/search?page=1`, {state: {filterParams: {clientFocus: adultClients}}});
  };
  const onChildPsychiatristsClick = () => {
    navigate(`/search?page=1`, {
      state: {filterParams: {clientFocus: childClients}},
    });
  };
  return (
    <HeroContainer>
      <HeroFigure3 />
      <HeroFigure2 />
      <HeroFigure1 />
      <TextBox>
        <Header>
          Best Psychiatrist Network -<br /> Elevate Your Care!
        </Header>
        <Text>
          Only the best private practice M.D. and D.O. <br />
          psychiatrists for your care
        </Text>
        <BoldText>Help me find...</BoldText>
        <ButtonsBox>
          <DefaultButton $variant="transparent_white" onClick={onAdultPsychiatristsClick}>
            Adult Psychiatrists
          </DefaultButton>
          <DefaultButton $variant="transparent_white" onClick={onChildPsychiatristsClick}>
            Child/Adolescent Psychiatrists
          </DefaultButton>
        </ButtonsBox>
      </TextBox>
    </HeroContainer>
  );
};

export {Hero};
