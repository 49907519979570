import {FC} from 'react';
import {useTheme} from 'styled-components';
import {Container, NavLinkStyled, NavMenu, SideBar} from './LoggedInLayout.styled';
import {Outlet} from 'react-router-dom';
import {privateMenuLinks} from '../../constants/privateMenuLinks';
import {adminLinks} from 'constants/adminLinks';
import {LogOutBtn} from 'shared-ui-elements/LogOutBtn/LogOutBtn';
import {useAppSelector} from 'hooks/redux';
import {AuthState} from 'store/reducers/auth/authSlice';

const LoggedInLayout: FC = () => {
  const {isDesktop} = useTheme();
  const {user} = useAppSelector(state => state.auth) as AuthState;
  const links = [
    ...privateMenuLinks,
    ...(user?.role.includes('admin') ? adminLinks : []),
  ];

  return (
    <Container>
      {isDesktop && (
        <SideBar>
          <NavMenu>
            {links.map(({title, link}) => (
              <NavLinkStyled key={title} to={link} end={link === '/profile'}>
                {title}
              </NavLinkStyled>
            ))}
          </NavMenu>
          <LogOutBtn />
        </SideBar>
      )}
      <Outlet />
    </Container>
  );
};

export {LoggedInLayout};
