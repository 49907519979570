import styled from 'styled-components';
import {getSpacing} from 'theme';
import {Heading} from 'shared-ui-elements/Heading.styled';

const ReviewsHeading = styled(Heading)`
  margin-bottom: ${props => (props.theme.isMobile ? '24px' : '60px')};
`;

const CardsContainer = styled.ul`
  /* display: flex;
  align-items: stretch;
  justify-content: center; */

  /* padding: 15px; */

  /* overflow-x: auto;
  gap: ${getSpacing('md')}; */
`;

const NoBrakeText = styled.span`
  white-space: nowrap;
`;

export {Heading, CardsContainer, ReviewsHeading, NoBrakeText};
