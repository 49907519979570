import {FC} from 'react';
import {BtnWrap} from './LogOutBtn.styled';
import {ReactComponent as LogoutIcon} from 'assets/logoutIcon.svg';
import {useAppDispatch} from 'hooks/redux';
import {logOut} from 'store/reducers/auth/authSlice';

const LogOutBtn: FC = () => {
  const dispatch = useAppDispatch();
  const onLogOut = () => dispatch(logOut());

  return (
    <BtnWrap onClick={onLogOut}>
      <LogoutIcon />
      <p>Log Out</p>
    </BtnWrap>
  );
};

export {LogOutBtn};
