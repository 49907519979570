import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {getColor, getSpacing} from 'theme';
import arrowIcon from 'assets/arrow.svg';
import {DefaultButton} from 'shared-ui-elements/DefaultButton.styled';

const MobileMenuContainer = styled.div<{
  readonly $isHidden?: boolean;
}>`
  width: 100%;
  height: 100%;
  padding-top: 32px;
  padding-bottom: 64px;
  flex-grow: 1;
  display: ${props => (props.$isHidden ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${getColor('mainBg')};
  ${props => (props.$isHidden ? {opacity: 0, pointerEvents: 'none'} : {opacity: 1})}
`;

const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => (props.theme.isMobile ? '16px' : '28px')};
`;

const NavLinkStyled = styled(NavLink)`
  display: flex;
  align-items: center;
  font-size: ${props => (props.theme.isMobile ? '16px' : '24px')};
  color: ${getColor('mainText')};

  &.active::before {
    content: '';
    display: block;
    width: 16px;
    height: 8px;
    background-image: url(${arrowIcon});
    margin-right: 12px;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  gap: ${getSpacing('sm')};
`;

const AuthBtn = styled(DefaultButton)`
  width: 167px;
  padding: 12px 0px;
  display: flex;
  justify-content: center;
`;

const PrivateMenuTitle = styled.p`
  text-align: center;
  font-size: ${props => (props.theme.isMobile ? '14px' : '20px')};
  color: ${getColor('secBg')};
  margin-top: 56px;
  margin-bottom: 16px;
`;

export {
  MobileMenuContainer,
  MenuList,
  ButtonsBox,
  NavLinkStyled,
  AuthBtn,
  PrivateMenuTitle,
};
