import {FC} from 'react';
import {
  AuthBtn,
  ButtonsBox,
  MenuList,
  MobileMenuContainer,
  NavLinkStyled,
  PrivateMenuTitle,
} from './MobileMenu.styled';
import {menuLinks} from '../../../constants/menuLinks';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'hooks/redux';
import {LogOutBtn} from 'shared-ui-elements/LogOutBtn/LogOutBtn';
import {privateMenuLinks} from 'constants/privateMenuLinks';
import {AuthState} from 'store/reducers/auth/authSlice';
import {adminLinks} from 'constants/adminLinks';

interface IMobileMenu {
  readonly isHidden?: boolean;
  readonly onLinkClick?: (link?: string) => any;
}
const MobileMenu: FC<IMobileMenu> = ({isHidden = false, onLinkClick = () => {}}) => {
  const navigate = useNavigate();
  const onJoinNow = () => {
    onLinkClick();
    navigate('/sign-up');
  };

  const logInNow = () => {
    onLinkClick();
    navigate('/sign-in');
  };

  const {user, isLoggedIn} = useAppSelector(state => state.auth) as AuthState;
  const isUserAdmin = user?.role.includes('admin');

  return (
    <MobileMenuContainer $isHidden={isHidden}>
      <div>
        <MenuList>
          {menuLinks.map(({title, link}, index) => (
            <NavLinkStyled to={link} key={index} onClick={() => onLinkClick(link)}>
              {title}
            </NavLinkStyled>
          ))}
        </MenuList>
        {isLoggedIn && (
          <div>
            <PrivateMenuTitle>My Account</PrivateMenuTitle>
            <MenuList>
              {privateMenuLinks.map(({title, link}, index) => (
                <NavLinkStyled
                  to={link}
                  key={index}
                  onClick={() => onLinkClick(link)}
                  end={link === '/profile'}>
                  {title}
                </NavLinkStyled>
              ))}
            </MenuList>
          </div>
        )}
      </div>
      {isUserAdmin && (
        <div>
          <PrivateMenuTitle>Admin panel</PrivateMenuTitle>
          <MenuList>
            {adminLinks.map(({title, link}, index) => (
              <NavLinkStyled
                to={link}
                key={index}
                onClick={() => onLinkClick(link)}
                end={link === '/profile'}>
                {title}
              </NavLinkStyled>
            ))}
          </MenuList>
        </div>
      )}
      {isLoggedIn ? (
        <LogOutBtn />
      ) : (
        <ButtonsBox>
          <AuthBtn $variant="transparent_black" onClick={logInNow}>
            Log In
          </AuthBtn>
          <AuthBtn onClick={onJoinNow}>Join Now</AuthBtn>
        </ButtonsBox>
      )}
    </MobileMenuContainer>
  );
};

export {MobileMenu};
