import {FC} from 'react';
import {Hero} from './components/Hero/Hero';
import {Benefits} from './components/Benefits/Benefits';
import {Stats} from './components/Stats/Stats';
import {Features} from './components/Features/Features';
import {Reviews} from './components/Reviews/Reviews';
import {JoinNow} from '../../components/JoinNow/JoinNow';
import {Cities} from './components/Cities/Cities';

const Home: FC = () => {
  return (
    <>
      <Hero />
      <Benefits />
      <Stats />
      <Features />
      <Reviews />
      <JoinNow />
      <Cities />
    </>
  );
};

export {Home};
