import styled from 'styled-components';
import {getColor, getFontSize, getSpacing} from 'theme';

const StatsContainer = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${getColor('lightText')};
  text-align: center;
  font-size: ${getFontSize('pSm')};
`;

const StatsItem1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: ${props => (props.theme.isMobile ? '150px' : '192px')};
  min-width: ${props => (props.theme.isMobile ? '160px' : '198px')};
  background-color: rgba(0, 19, 60, 1);
`;

const StatsItem2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: ${props => (props.theme.isMobile ? '150px' : '192px')};
  min-width: ${props => (props.theme.isMobile ? '160px' : '198px')};

  background-color: rgba(0, 46, 148, 1);
`;

const StatsItem3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  min-width: ${props => (props.theme.isMobile ? '320px' : '395px')};
  height: ${props => (props.theme.isMobile ? '176px' : '192px')};
  background-color: rgba(0, 61, 198, 1);
`;

const PaddingBox = styled.div`
  padding-left: ${props => (props.theme.isMobile ? '16px' : '50px')};
  padding-right: ${props => (props.theme.isMobile ? '16px' : '50px')};
`;

const BoldText = styled.span`
  font-size: ${getFontSize('h2Md')};
  font-weight: 700;
  margin-bottom: ${getSpacing('sm')};
`;

const MediumText = styled.p`
  font-size: ${props => (props.theme.isMobile ? '20px' : '28px')};
  display: inline;
  max-width: 560px;
`;

const ItalicText = styled(MediumText)`
  font-style: italic;
  font-weight: 500;
`;

export {
  StatsContainer,
  StatsItem1,
  StatsItem2,
  StatsItem3,
  PaddingBox,
  BoldText,
  MediumText,
  ItalicText,
};
