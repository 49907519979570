import {DefaultTheme} from 'styled-components';

export const theme = (
  {isMobile, isTablet, isDesktop} = {
    isMobile: false,
    isTablet: false,
    isDesktop: true,
  },
): DefaultTheme => ({
  isMobile,
  isTablet,
  isDesktop,
  colors: {
    mainBg: 'rgb(256, 256, 256)',
    secBg: 'rgba(0, 46, 148)',
    extraBg: 'rgba(255, 232, 205, 1)',
    mainText: 'rgb(0, 0, 0)',
    secText: 'rgba(204, 204, 204, 1)',
    lightText: 'rgb(256, 256, 256)',
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  fontSizes: isMobile
    ? {
        h1: '32px',
        h2Lg: '32px',
        h2Md: '24px',
        h2Sm: '20px',
        pLg: '16px',
        pMd: '16px',
        pSm: '14px',
      }
    : {
        h1: '56px',
        h2Lg: '48px',
        h2Md: '40px',
        h2Sm: '24px',
        pLg: '20px',
        pMd: '18px',
        pSm: '16px',
      },
});

export const MOBILE_BREAKPOINT = 576;
export const TABLET_BREAKPOINT = 1200;

export function getColor(color: keyof DefaultTheme['colors']) {
  return (props: any) => {
    const themeFromProps = props.theme as DefaultTheme;
    return themeFromProps.colors[color];
  };
}

export function getSpacing(spacing: keyof DefaultTheme['spacing']) {
  return (props: any) => {
    const themeFromProps = props.theme as DefaultTheme;
    return themeFromProps.spacing[spacing];
  };
}

export function getFontSize(size: keyof DefaultTheme['fontSizes']) {
  return (props: any) => {
    const themeFromProps = props.theme as DefaultTheme;
    return themeFromProps.fontSizes[size];
  };
}
