import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './styles.css';

interface ISlider<T> {
  readonly slide: React.ComponentType<T>;
  readonly data: T[];
}

const Slider = <T extends object>({data, slide: Slide}: ISlider<T>) => {
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      spaceBetween={16}
      slidesPerView="auto"
      loop={true}
      pagination={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      onInit={(swiper: any) => {
        const visibleSlidesCount = swiper.slidesPerViewDynamic();
        const slidesCount = swiper.slides.length;

        if (visibleSlidesCount === slidesCount) {
          swiper.autoplay.stop();
        }
      }}>
      {data.map((item, index) => (
        <SwiperSlide style={{width: 'auto', flexShrink: 0}} key={index}>
          <Slide {...(item as T)} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export {Slider};
