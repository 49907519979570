import styled from 'styled-components';
import {getColor, getFontSize, getSpacing} from 'theme';
import {DefaultButton} from 'shared-ui-elements/DefaultButton.styled';
import {Heading} from 'shared-ui-elements/Heading.styled';

const CitiesHeader = styled(Heading)`
  text-align: center;
  margin-bottom: ${props => (props.theme.isMobile ? '24px' : '80px')};
`;

const CitiesList = styled.ul<{$isExpanded: boolean}>`
  width: 100%;
  position: relative;
  max-height: ${props => (props.$isExpanded ? 'none' : '190px')};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${props => [props.theme.isMobile ? '8px' : '16px']};
  transition: max-height 0.3s ease;
  overflow: hidden;
  margin-bottom: 18px;
`;

const CityItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${getSpacing('sm')};
  font-size: ${getFontSize('pLg')};
`;

const CityButton = styled(DefaultButton)`
  padding-left: ${props => (props.theme.isMobile ? '8px' : '24px')};
  padding-right: ${props => (props.theme.isMobile ? '8px' : '24px')};
`;

const Point = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${getColor('secBg')};
`;

const GradientOverlay = styled.div`
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none; /* Забезпечує, що градієнт не перехоплює кліки */
`;

const FlexCenterBox = styled.div`
  display: flex;
  justify-content: center;
`;

const ExpandBtn = styled.button`
  border: none;
  border-bottom: 1px solid ${getColor('secBg')};
  color: ${getColor('secBg')};
  background-color: transparent;
`;

export {
  CitiesHeader,
  CitiesList,
  CityItem,
  Point,
  GradientOverlay,
  FlexCenterBox,
  ExpandBtn,
  CityButton,
};
