import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainWrap = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;

const Main = styled.main`
  /* align-self: center; */
  display: flex;
  flex-direction: column;
  max-width: 2100px;
  width: 100%;
`;

const OutletWrap = styled.div<{readonly $isHidden?: boolean}>`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => (props.$isHidden ? {display: 'none'} : {display: 'flex'})}
`;

export {Container, MainWrap, Main, OutletWrap};
