import {FC} from 'react';
import {
  SocialsBox,
  FooterContainer,
  FooterMenuBox,
  StyledFooter,
  FooterTopBox,
  FooterBottomBox,
  StyledLink,
} from './Footer.styled';

import {NavLink} from 'react-router-dom';

import {ReactComponent as Logo} from 'assets/logo.svg';
import {ReactComponent as FacebookLogo} from 'assets/facebook_icon.svg';
import {ReactComponent as InstagramLogo} from 'assets/instagram_icon.svg';
import {ReactComponent as XLogo} from 'assets/x_icon.svg.svg';
import {ReactComponent as LinkedinLogo} from 'assets/linkedIn_icon.svg';
import {ReactComponent as YtLogo} from 'assets/yt_icon.svg';
import {menuLinks} from '../../../constants/menuLinks';

const Footer: FC = () => {
  return (
    <StyledFooter>
      <FooterContainer>
        <FooterTopBox>
          <NavLink to="/">
            <Logo />
          </NavLink>
          <FooterMenuBox>
            {menuLinks.map(({title, link}, index) => (
              <NavLink to={link} key={index}>
                {title}
              </NavLink>
            ))}
          </FooterMenuBox>
          <SocialsBox>
            <a href="/">
              <FacebookLogo />
            </a>
            <a href="/">
              <InstagramLogo />
            </a>
            <a href="/">
              <XLogo />
            </a>
            <a href="/">
              <LinkedinLogo />
            </a>
            <a href="/">
              <YtLogo />
            </a>
          </SocialsBox>
        </FooterTopBox>
        <FooterBottomBox>
          <p>© 2024 Best Psychiatrists Network. All rights reserved.</p>
          <StyledLink href="/">Privacy Policy</StyledLink>
          <StyledLink href="/">Terms and Conditions</StyledLink>
          <StyledLink href="/">Cookie Policy</StyledLink>
        </FooterBottomBox>
      </FooterContainer>
    </StyledFooter>
  );
};

export {Footer};
