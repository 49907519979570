import styled from 'styled-components';
import {getColor, getFontSize, getSpacing} from 'theme';
import image from 'assets/features_section_img.jpg';
import {DefaultButton} from 'shared-ui-elements/DefaultButton.styled';
import {Heading} from 'shared-ui-elements/Heading.styled';

const FeaturesContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: stretch; */
  align-items: stretch;
  width: 100%;
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${props => (props.theme.isMobile ? '340px' : '480px')};
  height: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '390px';
      case isTablet:
        return '485px';

      default:
        return '500px';
    }
  }};
  background-color: ${getColor('extraBg')};
`;

const TextBox = styled.div`
  max-width: ${props => (props.theme.isMobile ? '340px' : '480px')};
  padding-left: ${getSpacing('md')};
  padding-right: ${getSpacing('md')};
`;

const ImgContainer = styled.div`
  flex: 1;
  /* height: ${props => (props.theme.isMobile ? '390px' : '580px')}; */
  height: auto;
  min-width: ${props => (props.theme.isMobile ? '340px' : '480px')};
`;

const Image = styled.img.attrs(() => ({
  src: image,
  alt: 'patient and doctor picture',
}))`
  width: 100%;
  height: 100%;
  max-height: 500px;
  display: block;
  object-fit: cover;
  object-position: left;
`;

const FeaturesHeading = styled(Heading)`
  font-size: ${getFontSize('h2Md')};
  margin-bottom: 48px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing('md')};
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${getSpacing('md')};
  font-size: ${getFontSize('pLg')};
`;

const Button = styled(DefaultButton)`
  padding: 12px 24px;
  margin-top: ${props => (props.theme.isMobile ? '24px' : '48px')};
`;

const ArrowIconWrap = styled.div`
  width: 22px;
  height: 11px;
`;

export {
  FeaturesContainer,
  TextContainer,
  TextBox,
  Image,
  ImgContainer,
  FeaturesHeading,
  List,
  ListItem,
  Button,
  ArrowIconWrap,
};
