import {ThemeProvider} from 'styled-components';
import {useGetScreenWidth} from './hooks/getScreenWidth';
import {MOBILE_BREAKPOINT, TABLET_BREAKPOINT} from './theme';
import {theme} from './theme';
import {GlobalStyle} from './GlobalStyle';
import {SharedLayout} from 'components/SharedLayout/SharedLayout';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Home} from 'pages/home/Home';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import api from 'api/axios';
import {useAppDispatch} from 'hooks/redux';

import {LoggedInLayout} from 'components/LoggedInLayout/LoggedInLayout';
import RestrictedRoute from 'RestrictedRoute';
import PrivateRoute from 'PrivateRoute';
import {useAuthApi} from 'hooks/useAuth';
import AdminRoute from 'AdminRoute';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <SharedLayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: '/about',
          async lazy() {
            let {About} = await import('pages/about/About');
            return {
              Component: () => <About />,
            };
          },
        },
        {
          path: '/join-us',
          async lazy() {
            let {JoinUs} = await import('pages/joinUs/JoinUs');
            return {
              Component: () => <JoinUs />,
            };
          },
        },
        {
          path: '/search',
          async lazy() {
            let {Search} = await import('pages/search/Search');
            return {
              Component: () => <Search />,
            };
          },
        },
        {
          path: '/search/:id',
          async lazy() {
            let {DoctorProfilePublic} = await import(
              'pages/doctorProfile/DoctorProfilePublic'
            );
            return {
              Component: () => <DoctorProfilePublic />,
            };
          },
        },
        {
          path: '/articles',
          async lazy() {
            let {Articles} = await import('pages/articles/Articles');
            return {
              Component: () => <Articles />,
            };
          },
        },
        {
          path: '/articles/:id',
          async lazy() {
            let {ArticlePage} = await import('pages/articlePage/ArticlePage');
            return {
              Component: () => <ArticlePage />,
            };
          },
        },
        {
          path: '/sign-up',
          async lazy() {
            let {SignUp} = await import('pages/signUp/SignUp');
            return {
              Component: () => (
                <RestrictedRoute component={<SignUp />} redirectTo="/profile" />
              ),
            };
          },
        },
        {
          path: '/confirm-email',
          async lazy() {
            let {ConfirmEmail} = await import('components/ConfirmEmail/ConfirmEmail');
            return {
              Component: () => (
                <RestrictedRoute component={<ConfirmEmail />} redirectTo="/profile" />
              ),
            };
          },
        },
        {
          path: '/sign-in',
          async lazy() {
            let {SignIn} = await import('pages/signIn/SignIn');
            return {
              Component: () => (
                <RestrictedRoute component={<SignIn />} redirectTo="/profile" />
              ),
            };
          },
        },
        {
          path: '/profile',
          element: <PrivateRoute component={<LoggedInLayout />} redirectTo="/" />,
          children: [
            {
              index: true,
              async lazy() {
                let {DoctorProfilePrivate} = await import(
                  'pages/doctorProfile/DoctorProfilePrivate'
                );
                return {
                  Component: () => <DoctorProfilePrivate />,
                };
              },
            },
            {
              path: '/profile/messages',
              async lazy() {
                let {PatientMessaging} = await import(
                  'pages/patientMessaging/PatientMessaging'
                );
                return {
                  Component: () => <PatientMessaging />,
                };
              },
            },
            {
              path: '/profile/connect',
              async lazy() {
                let {ConnectWithColleagues} = await import(
                  'pages/connectWithColleagues/ConnectWithColleagues'
                );
                return {
                  Component: () => <ConnectWithColleagues />,
                };
              },
            },
            {
              path: '/profile/posts',
              async lazy() {
                let {MyPosts} = await import('pages/myPosts/MyPosts');
                return {
                  Component: () => <MyPosts />,
                };
              },
            },
            {
              path: '/profile/forum',
              async lazy() {
                let {Forum} = await import('pages/forum/Forum');
                return {
                  Component: () => <Forum />,
                };
              },
            },
            {
              path: '/profile/forum/:id',
              async lazy() {
                let {ForumDiscussion} = await import(
                  'pages/forumDiscussion/ForumDiscussion'
                );
                return {
                  Component: () => <ForumDiscussion />,
                };
              },
            },
            {
              path: '/profile/settings',
              async lazy() {
                let {ProfileSettings} = await import(
                  'pages/profileSettings/ProfileSettings'
                );
                return {
                  Component: () => <ProfileSettings />,
                };
              },
            },
          ],
        },
        {
          path: '/admin',
          element: <AdminRoute component={<LoggedInLayout />} redirectTo="/" />,
          children: [
            {
              path: '/admin/users',
              async lazy() {
                let {AdminUsers} = await import('pages/adminUsers/AdminUsers');
                return {
                  Component: () => <AdminUsers />,
                };
              },
            },
          ],
        },
      ],
    },
  ],
  {
    basename: '/',
  },
);

function App() {
  const [screenWidth] = useGetScreenWidth();
  const [isAllowAccess, setIsAllowAccess] = useState(false);
  const isMobile = screenWidth < MOBILE_BREAKPOINT;
  const isTablet = screenWidth < TABLET_BREAKPOINT && !isMobile;
  const isDesktop = screenWidth >= TABLET_BREAKPOINT;
  const {setupRefreshInterceptor} = useAuthApi();
  const setupRefreshInterceptorCb = useCallback(setupRefreshInterceptor, []);

  const isPromptedRef = useRef(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const requestAccess = () => {
      if (isPromptedRef.current) {
        return;
      }
      isPromptedRef.current = true;

      const usersStr = process.env.REACT_APP_USER_CREDS ?? '[]';
      const users = JSON.parse(usersStr);
      const userName = window.prompt('Enter user:');
      const password = window.prompt('Enter password:');

      const user = users.find((usr: any) => usr.name === userName);
      if (!user || user.password !== password) {
        window.alert('Access locked');
      } else {
        setIsAllowAccess(true);
        window.alert('Welcome');
      }
    };

    requestAccess();
  }, []);

  useEffect(() => {
    const mockLogIn = () => {
      // dispatch(logIn({token: 'azazza', user: doctors[0]}));
    };

    const apiTest = async () => {
      try {
        const res = await api.get('/check');
        console.log('API TEST: ', res.data);
      } catch (error) {
        console.log('API TEST: ', error);
      }
    };

    setupRefreshInterceptor();
    apiTest();
    // mockLogIn();
  }, [dispatch, setupRefreshInterceptorCb]);

  return (
    <ThemeProvider theme={theme({isMobile, isTablet, isDesktop})}>
      <GlobalStyle />
      {isAllowAccess ? <RouterProvider router={router} /> : <></>}
    </ThemeProvider>
  );
}

export default App;
