import styled from 'styled-components';
import {getColor, getFontSize, getSpacing} from 'theme';
import {DefaultSectionContainer} from 'shared-ui-elements/DefaultSectionContainer.styled';

const BenefitsContainer = styled(DefaultSectionContainer)`
  @media screen and ((min-width: 768px) and (max-width: 1200px)) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;

const DiagramContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: ${props => (props.theme.isDesktop ? '340px' : '0px')};
  height: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '280px';
      case isTablet:
        return '280px';

      default:
        return '720px';
    }
  }};
  /* width: 1200px; */
`;

interface ICircle {
  readonly $radius: number;
}

const InnerCircle = styled.div<ICircle>`
  width: ${props => props.$radius * 2}px;
  height: ${props => props.$radius * 2}px;
  border-radius: 50%;
  border: 5px solid ${getColor('secBg')};
  border-left-color: transparent;
  border-bottom-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const OuterCircle = styled.div<ICircle>`
  width: ${props => props.$radius * 2}px;
  height: ${props => props.$radius * 2}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: rotate(45deg);
`;

const Ray = styled.line`
  stroke: url(#gradient);
  stroke-width: 2;
`;

const SvgContainer = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

const CircleContainer = styled.div`
  position: relative;
  width: 85%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(255, 251, 246);
  transform: rotate(-45deg);
`;

const TextCircle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  box-shadow: 2.79px 1.86px 3.72px 0px rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    180deg,
    #ffffff 7.79%,
    #fafafa 34.63%,
    #eeeeee 63.53%,
    #d9d9d9 93.47%,
    #c9c9c9 111.02%
  );
`;

const HeadingText = styled.h2`
  font-size: ${getFontSize('h2Md')};
  ${props => (props.theme.isTablet ? {fontSize: '24px'} : {})}
  font-weight: 700;
  text-align: center;
`;

interface IPoint {
  readonly $posX: number;
  readonly $posY: number;
}
const BluePoint = styled.div<IPoint>`
  position: absolute;
  top: ${props => props.$posY}px;
  left: ${props => props.$posX}px;
  z-index: -1;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 6px solid rgb(204, 214, 236);
  background-color: ${getColor('secBg')};
`;

interface ICardBox {
  readonly $posX?: number;
  readonly $posY?: number;
  readonly $position: 'absolute' | 'static';
}
const CardBox = styled.div<ICardBox>`
  transform: ${props => (props.$position === 'absolute' ? 'rotate(-45deg)' : 'none')};
  position: ${props => props.$position};

  top: ${props => (props.$posY ? props.$posY - 200 : 0)}px;
  left: ${props => (props.$posX ? props.$posX - 90 : 0)}px;
`;

const CardsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => (props.theme.isMobile ? '8px' : '16px')};
  margin-top: ${getSpacing('xl')};
`;

export {
  BenefitsContainer,
  DiagramContainer,
  InnerCircle,
  OuterCircle,
  Ray,
  SvgContainer,
  CircleContainer,
  TextCircle,
  HeadingText,
  BluePoint,
  CardBox,
  CardsList,
};
