import {FC} from 'react';
import {
  FeaturesContainer,
  TextContainer,
  Image,
  ImgContainer,
  FeaturesHeading,
  ListItem,
  TextBox,
  Button,
  List,
  ArrowIconWrap,
} from './Features.styled';

import {ReactComponent as ArrowIcon} from 'assets/arrow.svg';
import {useNavigate} from 'react-router-dom';

const Features: FC = () => {
  const navigate = useNavigate();
  const onFindPsychiatrist = () => navigate('/search');
  return (
    <FeaturesContainer>
      <TextContainer>
        <TextBox>
          <FeaturesHeading>How does the platform work?</FeaturesHeading>
          <List>
            <ListItem>
              <ArrowIconWrap>
                <ArrowIcon width="22px" height="11px" />
              </ArrowIconWrap>
              <p>Search by zip code or state</p>
            </ListItem>
            <ListItem>
              <ArrowIconWrap>
                <ArrowIcon width="22px" height="11px" />
              </ArrowIconWrap>
              <p>Review psychiatrist profile and their areas of strength</p>
            </ListItem>
            <ListItem>
              <ArrowIconWrap>
                <ArrowIcon width="22px" height="11px" />
              </ArrowIconWrap>
              <p>Connect with your local psychiatrist </p>
            </ListItem>
            <ListItem>
              <ArrowIconWrap>
                <ArrowIcon width="22px" height="11px" />
              </ArrowIconWrap>
              <p>Let others know how easy it was </p>
            </ListItem>
          </List>
          <Button onClick={onFindPsychiatrist}>Find a Psychiatrist</Button>
        </TextBox>
      </TextContainer>
      <ImgContainer>
        <Image />
      </ImgContainer>
    </FeaturesContainer>
  );
};

export {Features};
