function calculateCoordinates(
  R_outer: number,
  R_inner: number,
  thetaDegrees: number,
) {
  // Convert angle to radians
  const thetaRadians = thetaDegrees * (Math.PI / 180);

  const coordsCenterShift = R_outer;

  // Outer circle coordinates
  const x_outer = coordsCenterShift + R_outer * Math.cos(thetaRadians);
  const y_outer = coordsCenterShift + R_outer * Math.sin(thetaRadians);

  // Inner circle coordinates
  const x_inner = coordsCenterShift + R_inner * Math.cos(thetaRadians);
  const y_inner = coordsCenterShift + R_inner * Math.sin(thetaRadians);

  return {
    outer: {x: x_outer, y: y_outer},
    inner: {x: x_inner, y: y_inner},
  };
}

export {calculateCoordinates};
