import {useAppSelector} from 'hooks/redux';
import {FC, ReactNode} from 'react';
const {Navigate} = require('react-router-dom');

interface IRestrictedRoute {
  component: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  redirectTo?: string;
}
const RestrictedRoute: FC<IRestrictedRoute> = ({
  component: Component,
  redirectTo = '/',
}) => {
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);
  return isLoggedIn ? <Navigate to={redirectTo} replace /> : Component;
};

export default RestrictedRoute;
