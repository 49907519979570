import styled from 'styled-components';
import heroFigure1 from 'assets/hero_figure1.png';
import heroFigure2 from 'assets/hero_figure2.png';
import heroFigure3 from 'assets/hero_figure3.png';
import {getColor, getFontSize, getSpacing} from 'theme';

const HeroContainer = styled.section`
  position: relative;
  display: flex;
  overflow: hidden;
  height: 699px;
  width: 100%;
  background: radial-gradient(
    circle at 50% 85%,
    rgba(46, 83, 101, 1) 0%,
    rgba(5, 10, 10, 1) 70%
  );
  padding-top: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '40px';
      case isTablet:
        return '40px';

      default:
        return '0px';
    }
  }};
  padding-left: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '16px';
      case isTablet:
        return '24px';

      default:
        return '120px';
    }
  }};
`;

const HeroFigure1 = styled.img.attrs(() => ({
  src: heroFigure1,
}))`
  width: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '233px';
      case isTablet:
        return '273px';

      default:
        return '410px';
    }
  }};
  height: auto;
  position: absolute;
  bottom: 0px;
  right: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '-36px';
      case isTablet:
        return '-20px';

      default:
        return '0px';
    }
  }};
`;
const HeroFigure2 = styled.img.attrs(() => ({
  src: heroFigure2,
}))`
  width: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '372px';
      case isTablet:
        return '436px';

      default:
        return '654px';
    }
  }};
  height: auto;
  position: absolute;
  bottom: 0;
  right: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '120px';
      case isTablet:
        return '200px';
      default:
        return '300px';
    }
  }};
`;
const HeroFigure3 = styled.img.attrs(() => ({
  src: heroFigure3,
}))`
  width: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '377px';
      case isTablet:
        return '443px';
      default:
        return '665px';
    }
  }};
  height: auto;
  position: absolute;
  bottom: -20px;
  right: ${props => {
    const {isMobile, isTablet} = props.theme;
    switch (true) {
      case isMobile:
        return '-50px';
      case isTablet:
        return '-20px';

      default:
        return '0px';
    }
  }};
`;

const TextBox = styled.div`
  align-self: ${props => (props.theme.isDesktop ? 'center' : 'flex-start')};
  position: relative;
  z-index: 2;
  color: ${getColor('lightText')};
  max-width: ${props => (props.theme.isMobile ? '420px' : '640px')};
`;

const Header = styled.h1`
  font-size: ${getFontSize('h1')};
  font-weight: 800;
  margin-bottom: ${props => (props.theme.isMobile ? '8px' : '16px')};
`;

const Text = styled.p`
  color: ${getColor('secText')};
  font-size: ${getFontSize('pMd')};
  margin-bottom: ${props => (props.theme.isMobile ? '24px' : '48px')};
`;

const BoldText = styled.p`
  font-weight: 700;
  font-size: ${getFontSize('h2Sm')};
  color: ${getColor('lightText')};
  margin-bottom: ${getSpacing('md')};
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  align-items: flex-start;
  gap: ${props => (props.theme.isMobile ? '8px' : '16px')};
`;

export {
  HeroContainer,
  HeroFigure1,
  HeroFigure2,
  HeroFigure3,
  TextBox,
  Header,
  Text,
  BoldText,
  ButtonsBox,
};
