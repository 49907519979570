import {FC} from 'react';

const SvgGradient: FC = () => {
  return (
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset="0%"
          style={{stopColor: 'rgba(0, 61, 198, 1)', stopOpacity: 1}}
        />
        <stop
          offset="100%"
          style={{stopColor: 'rgba(0, 61, 198, 0)', stopOpacity: 1}}
        />
      </linearGradient>
    </defs>
  );
};

export {SvgGradient};
