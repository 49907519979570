import {ClientFocus} from 'types/iDoctor';

export const childClients: ClientFocus[] = [
  'Toddler',
  'Children (6-10yo)',
  'Preteen',
  'Teenager',
];
export const adultClients: ClientFocus[] = ['Adults', 'Elderly (65+)'];

export const clientsFocus: ClientFocus[] = [...childClients, ...adultClients];
