import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IDoctor} from 'types/iDoctor';

interface IToken {
  access_token: string;
  refresh_token: string;
}

interface AuthStateLoggedIn {
  isLoggedIn: true;
  token: IToken;
  user: IDoctor;
  isLoading: boolean;
  error: string | null;
}

interface AuthStateLoggedOut {
  isLoggedIn: false;
  token: null;
  user: null;
  isLoading: boolean;
  error: string | null;
}

export type AuthState = AuthStateLoggedIn | AuthStateLoggedOut;

const initialState: AuthState = {
  isLoggedIn: false,
  token: null,
  user: null,
  isLoading: false,
  error: null,
};

interface ILogIn {
  user: IDoctor;
  token: IToken;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn: (state: AuthState, action: PayloadAction<ILogIn>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
    refreshToken: (state: AuthState, action: PayloadAction<IToken>) => {
      state.token = action.payload;
    },
    updUser: (state: AuthState, action: PayloadAction<IDoctor>) => {
      state.user = action.payload;
    },
    logOut: (state: AuthState) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
    },
  },
});

export const {logIn, logOut, refreshToken, updUser} = authSlice.actions;
export {authSlice};
