import {FC} from 'react';
import {
  AuthButtonsBox,
  HeaderContainer,
  HeaderMenuBox,
  NavLinkStyled,
  StyledHeader,
  StyledLogo,
  UserBox,
  UserName,
  UserPosition,
} from './Header.styled';
import {DefaultButton} from 'shared-ui-elements/DefaultButton.styled';
import {NavLink, useNavigate} from 'react-router-dom';
import {useTheme} from 'styled-components';

import {ReactComponent as MenuIcon} from 'assets/burger_menu_icon.svg';
import {ReactComponent as CloseIcon} from 'assets/close_icon.svg';
import placeholder from 'assets/imagePlaceholder.jpg';
import {menuLinks} from '../../../constants/menuLinks';
import {useAppSelector} from 'hooks/redux';
import {Avatar} from 'shared-ui-elements/Avatar.styled';
import {AuthState} from 'store/reducers/auth/authSlice';

interface IHeader {
  onMobileMenuToggle: (isOpen: boolean) => any;
  isShowMobileMenu: boolean;
}

const Header: FC<IHeader> = ({onMobileMenuToggle, isShowMobileMenu}) => {
  const {isDesktop} = useTheme();

  const {isLoggedIn, user}: AuthState = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const onJoinNow = () => navigate('/sign-up');
  const onLogIn = () => navigate('/sign-in');
  const onUserClick = () => navigate('/profile');

  const toggleMenu = () => {
    onMobileMenuToggle(!isShowMobileMenu);
  };

  return (
    <StyledHeader>
      <HeaderContainer>
        <NavLink to="/" onClick={() => onMobileMenuToggle(false)}>
          <StyledLogo />
        </NavLink>
        {isDesktop && (
          <>
            <HeaderMenuBox>
              {menuLinks.map(({title, link}, index) => (
                <NavLinkStyled to={link} key={index}>
                  {title}
                </NavLinkStyled>
              ))}
            </HeaderMenuBox>
            {isLoggedIn ? (
              <UserBox onClick={onUserClick}>
                <div>
                  <UserName>{user.fullName}</UserName>
                  <UserPosition>{user.doctorInfo.title}</UserPosition>
                </div>
                <Avatar src={user.doctorInfo.avatarUrl ?? placeholder} />
              </UserBox>
            ) : (
              <AuthButtonsBox>
                <DefaultButton onClick={onLogIn}>Log In</DefaultButton>
                <DefaultButton $variant="transparent_black" onClick={onJoinNow}>
                  Join Now
                </DefaultButton>
              </AuthButtonsBox>
            )}
          </>
        )}
        {!isDesktop && (
          <button onClick={toggleMenu}>
            {isShowMobileMenu && <CloseIcon />} {!isShowMobileMenu && <MenuIcon />}
          </button>
        )}
      </HeaderContainer>
    </StyledHeader>
  );
};

export {Header};
