import styled from 'styled-components';

interface IHeading {
  readonly $size?: 'lg' | 'md' | 'sm';
  readonly $color?: 'white' | 'black';
}

const Heading = styled.h2<IHeading>`
  font-size: ${props => {
    if (props.$size === 'md') {
      return props.theme.fontSizes.h2Md;
    }
    if (props.$size === 'sm') {
      return props.theme.fontSizes.h2Sm;
    }
    return props.theme.fontSizes.h2Lg;
  }};
  color: ${props => {
    if (props.$color === 'white') {
      return props.theme.colors.lightText;
    }
    return props.theme.colors.mainText;
  }};
  font-weight: 700;
`;

Heading.defaultProps = {
  $size: 'lg',
  $color: 'black',
};

export {Heading};
