import {useAppSelector} from './hooks/redux';
import {FC} from 'react';
import {Navigate} from 'react-router-dom';

interface IPrivateRoute {
  readonly component: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  readonly redirectTo?: string;
}

const PrivateRoute: FC<IPrivateRoute> = ({component: Component, redirectTo = '/'}) => {
  const {isLoggedIn, isLoading} = useAppSelector(state => state.auth);
  const shouldRedirect = !isLoggedIn && !isLoading;
  return shouldRedirect ? <Navigate to={redirectTo} replace /> : Component;
};

export default PrivateRoute;
